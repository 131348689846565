<template>
  <div class="content-body">
    <!-- modal -->
    <b-modal
      id="file_upload_modal"
      ref="file_upload_modal"
      hide-footer
      title="Upload CSV File"
      size="lg"
    >
      <validation-observer ref="UploadForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleFileUpload">
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
            >
              <b-form-file
                v-model="file"
                accept=".csv"
                placeholder="Choose a CSV file or drop it here..."
                drop-placeholder="Drop file here..."
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-col>

          <b-col class="d-flex justify-content-center mt-2">
            <b-button
              type="submit"
              variant="success"
              class="mr-1"
              :disabled="invalid"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              Upload
            </b-button>

            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click.prevent="handleDownloadButtonClick"
            >
              <feather-icon icon="DownloadIcon" class="mr-50" />
              Download Sample File
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <div class="card">
      <div class="m-2">
        <div class="row">
          <h2 class="ml-2 font-weight-bold">All Assets</h2>
        </div>

        <div class="row justify-content-start my-2">
          <b-button
            type="button"
            @click="handleAddAssetClick()"
            class="ml-2"
            variant="success"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add New</span>
          </b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="primary"
            v-b-modal.file_upload_modal
          >
            <feather-icon icon="UploadIcon" class="mr-50" />
            <span class="align-middle">Upload File</span>
          </b-button>
        </div>

        <div class="col mt-2 mb-2 py-1 bg-light-secondary rounded">
          <b-row class="d-flex justify-content-between mb-2">
            <h4 class="mx-2 font-weight-bold">
              Filters <feather-icon class="ml-55" size="20" icon="FilterIcon" />
            </h4>
            <b-button
              variant="outline-danger"
              class="mx-2"
              size="sm"
              @click="handleClearButtonClick"
              ><feather-icon icon="XIcon" class="mr-50" />
              <span class="align-middle">Clear Filters</span></b-button
            >
          </b-row>

          <div class="row d-flex justify-content-start">
            <b-form-group label="Asset Name" class="mx-2">
              <b-input-group size="md">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Search.."
                />
              </b-input-group>
            </b-form-group>

            <b-form-group class="mx-2" label="Owner" variant="primary">
              <vue-autosuggest
                :suggestions="asset_owner_options"
                :limit="5"
                v-model="asset_owner_name"
                id="autosuggest__input"
                :input-props="ownerInputProps"
                @input="getAssetOwnersBySearchPhrase"
                @selected="onOwnerSelected"
              >
                <template
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <span style="{ display: 'flex', color: 'navyblue'}"
                    >{{ suggestion.item.firstname }}
                    {{ suggestion.item.lastname }}</span
                  >
                </template>
              </vue-autosuggest>
            </b-form-group>

            <b-form-group class="mx-2" label="Tag">
              <vue-autosuggest
                :suggestions="asset_tag_options"
                :limit="5"
                v-model="asset_tag_name"
                id="autosuggest__input"
                :input-props="tagInputProps"
                @input="getAssetTagsBySearchPhraseAndSetOptions"
                @selected="onTagSelected"
                :get-suggestion-value="getTagSuggestionValue"
              >
                <template
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <span style="{ display: 'flex', color: 'navyblue'}"
                    >{{ suggestion.item.title }}
                  </span>
                </template>
              </vue-autosuggest>
            </b-form-group>

            <b-form-group label="Category" class="mx-2">
              <validation-provider name="asset_type">
                <b-form-select
                  v-model="asset_type"
                  :options="asset_type_options"
                ></b-form-select>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Classification" class="mx-2">
              <validation-provider name="asset_class">
                <b-form-select
                  v-model="asset_class"
                  :options="asset_class_options"
                ></b-form-select>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Department" class="mx-2">
              <validation-provider name="department_type">
                <b-form-select
                  v-model="department"
                  :options="departmentOptions"
                ></b-form-select>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </div>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="dark"
      >
        <b-card>
          <table role="table" class="table b-table">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Asset Name</th>
                <th role="columnheader" scope="col">Owner</th>
                <th role="columnheader" scope="col">Value</th>
                <th role="columnheader" scope="col">Location</th>
                <th role="columnheader" scope="col">Action</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr v-for="asset in assets" :key="asset._id" role="row">
                <!--  @click="handleAssetClick(asset._id)" -->
                <td aria-colindex="1" role="cell">
                
                    <b-col>
                      <b-row style="margin-bottom: 2px">
                        <h5
                          class="text-primary font-weight-bold cursor-pointer"
                          @click="handleAssetClick(asset._id)"
                        >
                          {{ asset.asset_name }}
                        </h5>
                      </b-row>
                      <b-row>
                        <template v-for="tag in asset.tags">
                          <b-badge
                            :key="tag._id"
                            variant="light-primary"
                            class="mr-1"
                            style="margin-bottom: 2px"
                            >{{ tag.title }}</b-badge
                          >
                        </template>
                      </b-row>
                    </b-col>
                 
                </td>
                <td aria-colindex="2" role="cell">
                  <span class="font-weight-normal"
                    >{{ asset.owner.firstname }}
                    {{ asset.owner.lastname }}</span
                  >
                </td>
                <td aria-colindex="3" role="cell">
                  <b-badge
                    v-bind:variant="getVariant(parseInt(asset.asset_value))"
                    >{{
                      getScoreValueFromNumber(parseInt(asset.asset_value))
                    }}</b-badge
                  >
                </td>
                <td aria-colindex="5" role="cell">
                  <span class="font-weight-normal">{{
                    asset.asset_location[0]
                  }}</span>
                </td>

                <td aria-colindex="6" role="cell">
                  <b-dropdown
                    variant="outline-primary"
                    size="sm"
                    text="Actions"
                  >
                    <b-dropdown-item
                      @click="
                        $router.push({
                          name: 'asset-edit',
                          params: { id: asset._id },
                        })
                      "
                      >Edit</b-dropdown-item
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="showConfirmDeleteMsgBox(asset._id)"
                      >Delete</b-dropdown-item
                    >
                  </b-dropdown>
                </td>
                <!-- <td role="cell">{{ asset.asset_classification }}</td> -->
              </tr>
            </tbody>
          </table>

          <div class="m-2">
            <div class="row">
              <div
                class="d-flex align-items-center justify-content-center col-12"
              >
                <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.rows"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>
 
<script>
import router from "@/router";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {
  BCard,
  BPagination,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormSelect,
  BForm,
  BModal,
  VBModal,
  BFormFile,
  BCol,
  BRow,
  BBadge,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueAutosuggest } from "vue-autosuggest";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TagMixins from "@/mixins/TagMixins";

export default {
  components: {
    BCard,
    FeatherIcon,
    BPagination,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BForm,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BCol,
    BRow,
    BBadge,
    BOverlay,
    BSpinner,
  },
  name: "AssetPage",
  mixins: [TagMixins],
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      file: null,
      timeout: null,
      debounceMilliseconds: 250,

      selectedAction: null,
      actionOptions: [],

      showOverlay: false,

      asset_owner: null,
      asset_owner_name: "",
      asset_owner_options: [],
      ownerInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },

      asset_tag: null,
      asset_tag_name: "",
      asset_tag_options: [],
      selectedTag: null,
      tagInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },

      asset_type: null,
      asset_type_options: [{ value: null, text: "--Select Asset Category--" }],
      asset_type_name: "",

      asset_class: null,
      asset_class_name: "",
      asset_class_options: [
        { value: null, text: "--Select Asset Classification--" },
      ],

      department: null,
      departmentOptions: [{ value: null, text: "--Select Department--" }],
      departmentName: "",
      classInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },

      filter: "",
      assets: [],
      currentPage: 1,
      pagination: {
        lastPage: null,
        perPage: 10,
        rows: 1,
      },
    };
  },
  mounted() {
    this.getAssetsByPageNumber(this.currentPage);
    this.getAssetTypes();
    this.getAssetClassifications();
    this.getAllDepartments();
  },
  watch: {
    currentPage(newPage) {
      this.getAssetsByPageNumber(newPage);
    },
    filter(name) {
      this.getAssetsByPageNumber(this.currentPage);
    },
    asset_owner(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    asset_class(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    department(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    selectedTag(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    asset_type(val) {
      console.log(val);
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
  },
  methods: {
    getScoreValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        case 4:
          return "Very High";
        default:
          return "Very Low";
      }
    },
    getVariant(status) {
      switch (status) {
        case 1:
          return "info";
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
          return "danger";
        case 5:
          return "primary";
        default:
          return "info";
      }
    },

    handleDownloadButtonClick() {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_BASEURL}/assets/import/csv/sample-download`,
      };
      this.$http(options)
        .then((res) => {
          var blob = new Blob([res.data]);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, "sample.csv");
          } else {
            var a = window.document.createElement("a");

            a.href = window.URL.createObjectURL(blob, {
              type: "text/plain",
            });
            a.download = "sample.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((err) => console.log(err));
    },
    handleAddAssetClick() {
      router.push({ name: "asset-add" });
    },
    getAssets() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/assets`,
      };
      this.$http(options)
        .then((res) => {
          console.log();
          this.assets = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getAssetsByPageNumber(num) {
      if ((num !== null) | (num !== 0)) {
        this.showOverlay = true;
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets`,
          params: {
            page: num,
            search: this.filter !== "" ? this.filter : null,
            asset_owner_id: this.asset_owner ? this.asset_owner.user_id : null,
            asset_classification_id: this.asset_class ? this.asset_class : null,
            asset_category_id: this.asset_type ? this.asset_type : null,
            department_id: this.department ? this.department : null,
            tag: this.selectedTag ? this.selectedTag.title : null,
          },
        };
        this.$http(options)
          .then((res) => {
            this.assets = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.pagination.lastPage = res.data.data.last_page;
            this.pagination.perPage = res.data.data.per_page;
            this.pagination.rows = res.data.data.last_page * 10;
            this.showOverlay = false;
          })
          .catch((err) => {
            console.log("err", err);
            this.showOverlay = false;
          });
      }
    },
    getAssetsByFilter(search, page) {
      if ((search !== null) & (page !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets`,
          params: {
            page: page,
            search: search,
          },
        };
        this.$http(options)
          .then((res) => {
            // this.$set(this.assets, res.data.data.data);
            console.log("name", res.data.data.data);
            this.assets = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.pagination.lastPage = res.data.data.last_page;
            this.pagination.perPage = res.data.data.per_page;
            this.pagination.rows = res.data.data.last_page * 10;
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    handleAssetClick(id) {
      router.push({ name: "asset-details", params: { id: id } });
    },
    deleteAssetWithId(id) {
      const options = {
        method: "DELETE",
        url: `${process.env.VUE_APP_BASEURL}/assets/` + id,
      };
      this.$http(options)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Asset Deleted Successfully",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          if (this.filter !== "") {
            console.log("Asset Length", this.assets.length);
            if (this.assets.length == 1 && this.currentPage > 1) {
              this.getAssetsByFilter(this.filter, this.currentPage - 1);
            } else {
              this.getAssetsByFilter(this.filter, this.currentPage);
            }
          } else {
            if (this.assets.length == 1 && this.currentPage > 1) {
              this.getAssetsByPageNumber(this.currentPage - 1);
            } else {
              this.getAssetsByPageNumber(this.currentPage);
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "The Asset has not been Deleted",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showConfirmDeleteMsgBox(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this Asset.", {
          title: "Delete Asset",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteAssetWithId(id);
          }
        });
    },
    getTagSuggestionValue(suggestion) {
      return suggestion.item.title;
    },
    onTagSelected(val) {
      this.selectedTag = val.item;
    },
    getAssetTagsBySearchPhraseAndSetOptions(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.getAssetTagsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.asset_tag_options = [];
              let tags = res.data.data.data;
              this.asset_tag_options.push({ name: "tags", data: tags });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.asset_tag_options = [];
        this.asset_tag = null;
        this.asset_tag_name = "";
      }
    },
    getAssetOwnersBySearchPhrase(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: { search: searchPhrase, limit: 5 },
        };
        this.timeout = setTimeout(() => {
          this.$http(options)
            .then((res) => {
              this.asset_owner_options = [];
              let owners = res.data.data.data;
              console.log("owners", owners);
              this.asset_owner_options.push({ name: "owners", data: owners });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.asset_owner_options = [];
        this.asset_owner = null;
        this.asset_owner_name = "";
      }
    },
    onOwnerSelected(owner) {
      console.log("Owner", owner);
      this.asset_owner = owner.item;
      this.asset_owner_name = `${owner.item.firstname} ${owner.item.lastname}`;
    },
    getAssetTypes() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_ONECSFURL}/ast_category/search`,
      };
      this.$http(options)
        .then((res) => {
          let assettypes = res.data.data;
          for (let i = 0; i < assettypes.length; i++) {
            this.asset_type_options.push({
              value: assettypes[i]._id,
              text: assettypes[i].name,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getAssetClassifications() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/asset-classifications`,
        params: { dont_paginate: true },
      };
      this.$http(options)
        .then((res) => {
          let classifications = res.data.data;
          for (let i = 0; i < classifications.length; i++) {
            this.asset_class_options.push({
              value: classifications[i]._id,
              text: classifications[i].title,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    handleClearButtonClick() {
      this.asset_class = null;
      this.asset_owner = null;
      this.asset_owner_name = "";
      this.asset_type = null;
      this.filter = "";
      this.department = null;
      this.selectedTag = null;
      this.asset_tag = null;
      this.asset_tag_name = "";
      this.getAssetsByPageNumber(this.currentPage);
    },
    getAllDepartments() {
      this.departmentOptions = [{ value: null, text: "--Select Department--" }];
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/departments`,
        params: { dont_paginate: true },
      };
      this.$http(options).then((res) => {
        let newDepartments = res.data.data;
        for (var i = 0; i < newDepartments.length; i++) {
          this.departmentOptions.push({
            value: newDepartments[i]._id,
            text: newDepartments[i].title,
          });
        }
      });
    },
    showModal() {
      this.$refs["file_upload_modal"].show();
    },
    hideModal() {
      this.$refs["file_upload_modal"].hide();
    },
    handleFileUpload() {
      const bodyFormData = new FormData();
      bodyFormData.append("assets_list_csv", this.file);
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/assets/import/csv`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "File has been successfully Uploaded",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.hideModal();
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.hideModal();
        });
    },
  },
};
</script>

<style >
</style>
